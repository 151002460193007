import Http, { HttpForExternal } from "../../../common/request"

export const getActivityRecord = id => {
    return Http('/api/client/activity/record', { id })
}

export const draw = id => {
    return HttpForExternal('/api/client/auth/activity/draw', { id })
}

export const getDrawCount = id => {
    return HttpForExternal('/api/client/auth/activity/drawCount', { id })
}