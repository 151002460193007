import './index.css'
import { useEffect, useState, useRef } from 'react'
import { getPackageList } from './service'
import { registerBridgeFunc, callBridgeFunc } from '../../../common/bridge'
import { createPackageOrder } from './service'
import { safeParse, setToLocalStorage, stopPropagation } from '../../../common/util'
import { ExternalAuthCacheKey, ExternalChannelCacheKey } from '../../../config'
import PubSub from 'pubsub-js'
import { EventTip, EventType } from '../../../common/event'

const EndTime = new Date('2025-02-12 23:59:59').getTime()

function getCountDown() {
    const now = Date.now()
    if (now >= EndTime) {
        return ["00", "00", "00", "00"]
    }
    const ret = []
    let p = Math.floor((EndTime - now) / 1000)
    const day = Math.floor(p / (24 * 60 * 60))
    ret[0] = day < 10 ? '0' + day : '' + day
    p %= 24 * 60 * 60
    const hour = Math.floor(p / (60 * 60))
    ret[1] = hour < 10 ? '0' + hour : '' + hour
    p %= 60 * 60
    const min = Math.floor(p / 60)
    ret[2] = min < 10 ? '0' + min : '' + min
    p %= 60
    ret[3] = p < 10 ? '0' + p : '' + p
    return ret
}

function getPackageContent(item) {
    return <>
        {
            item.duration > 0 && <span><span className='highlight-data'>{Math.round(item.duration / 60)}</span>小时高峰时长</span>
        }
        {
            item.duration > 0 && item.days > 0 && <span>+</span>
        }
        {
            item.days > 0 && <span><span className='highlight-data'>{item.days}</span>天会员</span>
        }
        {
            item.queueCuttCard > 0 && <span>+<span className='highlight-data'>{item.queueCuttCard}</span>张插队卡</span>
        }
    </>
}

function Package() {
    const [countDown, setCountDown] = useState(["", "", "", ""])
    const [list, setList] = useState([])
    const [cid, setCid] = useState('')
    const [showPayChannel, togglePayChannel] = useState(false)
    const [price, setPrice] = useState('')
    const [payType, setPayType] = useState(2)
    const submitFlag = useRef(false)

    useEffect(() => {
        getPackageList().then(data => {
            setList(data)
        })
        registerBridgeFunc('SyncUserInfo', data => {
            data = safeParse(data)
            if (data && data.userinfo) {
                const { _channel } = data
                const { token } = data.userinfo
                setToLocalStorage(ExternalAuthCacheKey, token)
                setToLocalStorage(ExternalChannelCacheKey, _channel)
            }
        })
        registerBridgeFunc('SyncEventStatus', data => {
            if (data === 'RECHARGE_NEW_YEAR_RECHARGE_SUCCESS') {
                PubSub.publish(EventTip, { msg: '购买成功', type: EventType.Success })
                togglePayChannel(false)
            }
        })
        const timer = setInterval(() => {
            setCountDown(getCountDown())
        }, 1000)
        return () => {
            clearInterval(timer)
        }
    }, [])

    const goToPayChannel = item => {
        setCid(item.id)
        setPrice(item.androidPrice + '')
        togglePayChannel(true)
    }

    const closePayChannel = () => {
        togglePayChannel(false)
    }

    const goToPay = () => {
        if (submitFlag.current) {
            return
        }
        submitFlag.current = true
        createPackageOrder(cid, payType).then(data => {
            callBridgeFunc('StartRecharge', data)
        }).catch(e => {
            if (e.code === 401) {
                callBridgeFunc('UserLogin')
            } else {
                PubSub.publish(EventTip, { msg: e.message })
            }
        }).finally(() => {
            submitFlag.current = false
        })
    }

    return <div className='app-page-container'>
        <div className='app-page-window'>
            <div className='package-page-container'>
                <div className='package-countdown-wrap'>
                    <div className='package-countdown-block'>{countDown[0]}</div>
                    <div className='package-countdown-block'>{countDown[1]}</div>
                    <div className='package-countdown-block'>{countDown[2]}</div>
                    <div className='package-countdown-block'>{countDown[3]}</div>
                </div>
                <div>
                    {
                        list.map((item, i) => (<div className='package-item' key={item.id}>
                            <div className='package-name'>套餐{i + 1}</div>
                            <div className='package-content'>{getPackageContent(item)}</div>
                            <div className='package-activity-data'><span className='highlight-data'>{item.activityData}</span>次抽奖机会</div>
                            <div className='package-price'><span className='package-yen'>&yen;</span>{item.androidPrice}</div>
                            <div className='package-original-price'>原价：{item.price}</div>
                            <button className='package-buy-btn' onClick={() => goToPayChannel(item)}></button>
                        </div>))
                    }
                </div>
            </div>
        </div>
        {
            showPayChannel && <div className='app-page-container dialog nest' onClick={closePayChannel}>
                <div className='package-pay-container' onClick={stopPropagation}>
                    <i className='package-pay-close' onClick={closePayChannel}></i>
                    <div className='package-pay-price'><span className='package-pay-yen'>&yen;</span>{price}</div>
                    <div className='package-pay-line'>
                        <i className={payType === 2 ? 'package-pay-radio active' : 'package-pay-radio'} onClick={() => setPayType(2)}></i>
                    </div>
                    <div className='package-pay-line wechat'>
                        <i className={payType === 1 ? 'package-pay-radio active' : 'package-pay-radio'} onClick={() => setPayType(1)}></i>
                    </div>
                    <div className='package-pay-btn-line'>
                        <button className='package-pay-btn' onClick={goToPay}></button>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default Package