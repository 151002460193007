export const lerp = (start, end, alpha) => {
    return Math.round((1 - alpha) * start + alpha * end)
}

export const getFromLocalStorage = key => {
    return localStorage.getItem(key)
}

export const isString = s => typeof s === 'string'

export const setToLocalStorage = (key, value) => {
    localStorage.setItem(key, isString(value) ? value : JSON.stringify(value))
}

export const removeFromLocalStorage = key => {
    localStorage.removeItem(key)
}

export const safeParse = (str, defaultValue = {}) => {
    if (!isString(str) || !str.length) {
        return defaultValue
    }
    try {
        return JSON.parse(str)
    } catch (e) {
        console.error(e, str)
        return defaultValue
    }
}

export const parseQueryString = query => {
    const ret = Object.create(null)
    if (query) {
        query.substring(1).split('&').forEach(pair => {
            const [k, v] = pair.split('=')
            ret[k] = decodeURIComponent(v)
        })
    }
    return ret
}

export const getRem = () => {
    // const style = document.documentElement.style.fontSize
    // return Number(style.replace(/px/i, ''))
    const { innerWidth, innerHeight } = window
    return Math.min(innerWidth, innerHeight) * 100 / 375
}

export const getWindowSizeForLandscape = () => {
    const { innerWidth, innerHeight } = window
    return {
        width: Math.max(innerWidth, innerHeight),
        height: Math.min(innerWidth, innerHeight)
    }
}

export const getDay = timestamp => {
    const value = timestamp - Date.now()
    if (!value || value < 0) {
        return 0
    }
    return Math.ceil(value / (1000 * 60 * 60 * 24))
}

export const getTime = min => {
    if (!min) {
        return '00:00:00'
    }
    const ret = {
        hour: '00',
        min: '00'
    }
    let m = min % 60
    ret.min = (m < 10 ? '0' : '') + m
    min = Math.floor(min / 60)
    if (min) {
        ret.hour = (min < 10 ? '0' : '') + min
    }
    return `${ret.hour}:${ret.min}:00`
}

export const getTimeForSec = sec => {
    const ret = {
        hour: '00',
        min: '00',
        sec: '00'
    }
    if (sec) {
        let s = sec % 60
        ret.sec = (s < 10 ? '0' : '') + s
        sec = Math.floor(sec / 60)
        if (sec) {
            let m = sec % 60
            ret.min = (m < 10 ? '0' : '') + m
            sec = Math.floor(sec / 60)
            if (sec) {
                ret.hour = (sec < 10 ? '0' : '') + sec
            }
        }
    }
    return `${ret.hour}:${ret.min}:${ret.sec}`
}

export const getTimeTxt = min => {
    if (min < 60) {
        return min + '分钟'
    }
    return `${Math.floor(min / 60)}小时${min % 60 ? (min % 60) + '分钟' : ''}`
}

export const getHour = (min, withTxt = true) => {
    const value = (min / 60).toFixed(1)
    return value.replace('.0', '') + (withTxt ? '小时' : '')
}

export const getDayOfWeek = () => {
    const day = new Date().getDay()
    return day || 7
}

export const compareTime = (start, end) => {
    const startArr = start.split(':')
    const endArr = end.split(':')
    const s = { h: Number(startArr[0]), m: Number(startArr[1]) }
    const e = { h: Number(endArr[0]), m: Number(endArr[1]) }
    const now = new Date()
    const h = now.getHours()
    if (h < s.h || h > e.h) {
        return false
    }
    const m = now.getMinutes()
    if ((h === s.h && m < s.m) || (h === e.h && m > e.m)) {
        return false
    }
    return true
}

const fill2 = n => {
    return n < 10 ? '0' + n : '' + n
}

export const getFullTime = timestamp => {
    const time = new Date(timestamp)
    return `${time.getFullYear()}-${fill2(time.getMonth() + 1)}-${fill2(time.getDate())} ${fill2(time.getHours())}:${fill2(time.getMinutes())}:${fill2(time.getSeconds())}`
}

export const getDateTime = timestamp => {
    const time = new Date(timestamp)
    return `${time.getFullYear()}年${time.getMonth() + 1}月${time.getDate()}日`
}

export const showByPassword = str => {
    return str.split('').map(_ => '*').join('')
}

export const isPortrait = () => {
    const { clientWidth, clientHeight } = document.documentElement
    return clientHeight > clientWidth
}

export const getDayName = en => {
    return {
        'Monday': '周一',
        'Tuesday': '周二',
        'Wednesday': '周三',
        'Thursday': '周四',
        'Friday': '周五',
        'Saturday': '周六',
        'Sunday': '周日'
    }[en] || en
}

export const noop = () => { }

export const fixByGear = (gears, value) => {
    let abs = Number.MAX_SAFE_INTEGER
    let index = -1
    for (let i = 0; i < gears.length; i++) {
        let a = Math.abs(gears[i] - value)
        if (a < abs) {
            abs = a
            index = i
        }
    }
    return gears[index]
}

export const stopPropagation = e => {
    e.stopPropagation()
}