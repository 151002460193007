import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { useLocation, useRoutes, BrowserRouter } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion';
import Router from './router'
import reportWebVitals from './reportWebVitals';
import Tip from './components/tip';
import AppContext from './common/context';
// import Login from './pages/login';
// import Sms from './pages/login/sms';
// import Play from './pages/play';
// import Pay from './pages/pay';
// import Waiting from './pages/waiting';
// import PayCallback from './pages/pay/payCallback';
// import NewGuyPrize from './components/newGuyPrize';
// import { checkLoginState, getSystemConfig, getWechatCode, loginByWechat, saveUserInfoToLocal } from './common/service'
import useZoomMode from './hooks/useZoomMode';
// import useRefreshUser from './hooks/useRefreshUser';
// import FreeCard from './components/freeCard';
// import LvDetail from './pages/lvDetail';
// import Agreement from './pages/agreement';
// import SignIn from './components/signin';
// import CustomPanel from './components/customPanel';
// import PubSub from 'pubsub-js';
// import { EventTip, EventLoginCallback, EventLoginEffect } from './common/event';
// import useOrientationLock from './hooks/useOrientationLock';

function MotionRouterRoot() {
  const element = useRoutes(Router)
  const location = useLocation()

  if (!element) {
    return null
  }

  return (
    <AnimatePresence>
      {React.cloneElement(element, { key: location.pathname })}
    </AnimatePresence>
  )
}


function ContextRoot() {
  // const [showLoginPage, toggleLoginPage] = useState(defaultContext.showLoginPage)
  // const [showSmsPage, toggleSmsPage] = useState(defaultContext.showSmsPage)
  // const [isLogin, toggleLoginState] = useState(defaultContext.isLogin)
  // const [bindPhone, toggleBindPhone] = useState(defaultContext.bindPhone)
  // const [user, setUser] = useState(defaultContext.user)
  // const [loginPhone, setLoginPhone] = useState(defaultContext.loginPhone)
  // const [showPlayScreen, togglePlayScreen] = useState(defaultContext.showPlayScreen)
  // const [showPay, togglePay] = useState(defaultContext.showPay)
  // const [payIndex, setPayIndex] = useState(defaultContext.payIndex)
  // const [game, setGame] = useState(defaultContext.game)
  // const [sysConfig, setSysConfig] = useState(defaultContext.sysConfig)
  // const [userReadyState, toggleUserReadyState] = useState(defaultContext.userReadyState)
  // const [newGuyDuration, setNewGuyDuration] = useState(defaultContext.newGuyDuration)
  // const [showNewGuyDuration, toggleNewGuyDuration] = useState(defaultContext.showNewGuyDuration)
  // const [showFreeCardEntry, toggleFreeCardEntry] = useState(defaultContext.showFreeCardEntry)
  // const [freeCardList, setFreeCardList] = useState(defaultContext.freeCardList)
  // const [showFreeCard, toggleFreeCard] = useState(defaultContext.showFreeCard)
  // const [showWaiting, toggleWaiting] = useState(defaultContext.showWaiting)
  // const [waitingInfo, setWaitingInfo] = useState(defaultContext.waitingInfo)
  // const [showLvDetail, toggleLvDetail] = useState(defaultContext.showLvDetail)
  // const [lvDetailFromVertical, toggleLvDetailFromVertical] = useState(defaultContext.lvDetailFromVertical)
  // const [showAgreement, toggleAgreement] = useState(defaultContext.showAgreement)
  // const [agreementType, setAgreementType] = useState(defaultContext.agreementType)
  // const [showSignIn, toggleSignIn] = useState(defaultContext.showSignIn)
  // const [signInFromVertical, toggleSignInFromVertical] = useState(defaultContext.signInFromVertical)
  useZoomMode()
  // useRefreshUser(setUser)
  // useOrientationLock(showPlayScreen)

  // useEffect(() => {
  //   const listener = PubSub.subscribe(EventLoginCallback, (_, data) => {
  //     const { token, userInfo, freeDuration } = data
  //     saveUserInfoToLocal(token, userInfo, Date.now())
  //     toggleLoginState(true)
  //     setUser(userInfo)
  //     toggleUserReadyState(true)
  //     PubSub.publish(EventLoginEffect)
  //     if (freeDuration > 0) {
  //       setNewGuyDuration(freeDuration)
  //       toggleNewGuyDuration(true)
  //     }
  //   })

  //   return () => {
  //     PubSub.unsubscribe(listener)
  //   }
  // }, [])

  // useEffect(() => {
  //   getSystemConfig().then(data => {
  //     setSysConfig(data)
  //   })
  //   const ui = checkLoginState()
  //   if (ui) {
  //     toggleLoginState(true)
  //     setUser(ui)
  //   }
  //   const wechatCode = getWechatCode()
  //   wechatCode
  //     ? loginByWechat(wechatCode).then(data => {
  //       if (data.userInfo.phone) {
  //         PubSub.publish(EventLoginCallback, data)
  //       } else {
  //         window._temp_wechat_at = data.token
  //         window._temp_wechat_fd = data.freeDuration
  //         toggleBindPhone(true)
  //         toggleLoginPage(true)
  //         toggleUserReadyState(true)
  //       }
  //     }).catch(e => {
  //       toggleUserReadyState(true)
  //       PubSub.publish(EventTip, { msg: e.message })
  //     })
  //     : toggleUserReadyState(true)
  // }, [])

  return (
    <AppContext.Provider value={{
      // showLoginPage, toggleLoginPage,
      // showSmsPage, toggleSmsPage,
      // isLogin, toggleLoginState,
      // bindPhone, toggleBindPhone,
      // user, setUser,
      // loginPhone, setLoginPhone,
      // showPlayScreen, togglePlayScreen,
      // showPay, togglePay,
      // payIndex, setPayIndex,
      // game, setGame,
      // sysConfig, setSysConfig,
      // userReadyState,
      // newGuyDuration, setNewGuyDuration,
      // showNewGuyDuration, toggleNewGuyDuration,
      // showFreeCardEntry, toggleFreeCardEntry,
      // freeCardList, setFreeCardList,
      // toggleFreeCard,
      // showWaiting, toggleWaiting,
      // waitingInfo, setWaitingInfo,
      // toggleLvDetail,
      // lvDetailFromVertical, toggleLvDetailFromVertical,
      // toggleAgreement,
      // agreementType, setAgreementType,
      // showSignIn, toggleSignIn,
      // signInFromVertical, toggleSignInFromVertical
    }}>
      {/* <PayCallback /> */}
      <BrowserRouter>
        <MotionRouterRoot />
      </BrowserRouter>
      {/* <Play />
      <Waiting />
      <AnimatePresence>
        {
          showLvDetail && <LvDetail />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showSignIn && <SignIn />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showFreeCard && <FreeCard />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showPay && <Pay />
        }
      </AnimatePresence>
      {
        showNewGuyDuration && <NewGuyPrize />
      }
      <AnimatePresence>
        {
          showLoginPage && <Login />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showSmsPage && <Sms />
        }
      </AnimatePresence>
      <AnimatePresence>
        {
          showAgreement && <Agreement />
        }
      </AnimatePresence> */}
      {/* <CustomPanel></CustomPanel> */}
    </AppContext.Provider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <ContextRoot />
    <Tip />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
