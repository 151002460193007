import axios from "axios";
import { BaseUrl, Timeout, AuthCacheKey, ExternalAuthCacheKey, ExternalChannelCacheKey } from '../config'
import PubSub from "pubsub-js";
import { EventLogin, EventUserInvalid, AuthorityErrorFlag } from "./event";
import { getFromLocalStorage } from './util'
import AppChannel from "./channel";

const instance = axios.create({
    baseURL: BaseUrl,
    timeout: Timeout
})

export const Upload = (url, formData) => {
    const Authorization = getFromLocalStorage(AuthCacheKey)
    if (!Authorization) {
        PubSub.publish(EventUserInvalid)
        PubSub.publish(EventLogin)
        return Promise.reject(new Error(AuthorityErrorFlag))
    }
    if (!formData.has('_channel')) {
        formData.append('_channel', AppChannel)
    }
    const config = {
        headers: {
            Authorization,
            'Content-Type': 'multipart/form-data'
        }
    }
    return instance.post(url, formData, config).then(res => {
        const { data } = res
        const { code, message, data: ret } = data
        if (code === 401) {
            PubSub.publish(EventUserInvalid)
            PubSub.publish(EventLogin)
            throw new Error(AuthorityErrorFlag)
        }
        if (code !== 200) {
            const error = new Error(message || '服务繁忙，请稍后重试')
            error.code = code
            throw error
        }
        return ret
    })
}

export const HackHttp = (url, Authorization, data) => {
    data._channel = AppChannel
    return instance.post(url, data, { headers: { Authorization } }).then(res => {
        const { code, message, data: ret } = res.data
        if (code === 200) {
            return ret
        }
        const error = new Error(message || '服务繁忙，请稍后重试')
        error.code = code
        throw error
    })
}

export const Http = (url, data = {}) => {
    let config
    let option
    let silent
    if (data.auth) {
        const Authorization = getFromLocalStorage(AuthCacheKey)
        if (!Authorization) {
            if (data.option) {
                delete data.auth
                delete data.option
                return Http(url, data)
            }
            PubSub.publish(EventUserInvalid)
            if (!data.silent) {
                PubSub.publish(EventLogin)
            }
            return Promise.reject(new Error(AuthorityErrorFlag))
        }
        config = { headers: { Authorization } }
        option = data.option
        silent = data.silent
        delete data.auth
        delete data.option
        delete data.silent
    }
    if (data._channel === void 0) {
        data._channel = AppChannel
    }
    return instance.post(url, data, config).then(res => {
        const { data: resData } = res
        const { code, message, data: ret } = resData
        if (code === 401) {
            PubSub.publish(EventUserInvalid)
            if (option) {
                return Http(url, data)
            }
            if (!silent) {
                PubSub.publish(EventLogin)
            }
            throw new Error(AuthorityErrorFlag)
        }
        if (code !== 200) {
            const error = new Error(message || '服务繁忙，请稍后重试')
            error.code = code
            if (code === 201) {
                error.data = ret
            }
            throw error
        }
        return ret
    })
}

export const HttpForExternal = (url, data = {}) => {
    const Authorization = getFromLocalStorage(ExternalAuthCacheKey)
    const config = { headers: { Authorization } }
    data._channel = getFromLocalStorage(ExternalChannelCacheKey)
    return instance.post(url, data, config).then(res => {
        const { data: resData } = res
        const { code, message, data: ret } = resData
        if (code !== 200) {
            const error = new Error(message || '服务繁忙，请稍后重试')
            error.code = code
            throw error
        }
        return ret
    })
}

export default Http