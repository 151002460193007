import { noop } from "./util"

function connectWebviewJavascriptBridge(func) {
    const $bridge = window.WebViewJavascriptBridge
    if ($bridge) {
        return func($bridge)
    }
    const queueName = 'WVJBFuncs'
    if (window[queueName]) {
        return window[queueName].push(func)
    }
    window[queueName] = [func]
    document.addEventListener('WebViewJavascriptBridgeReady', function () {
        window[queueName].forEach(f => f(window.WebViewJavascriptBridge))
    })
}

export const callBridgeFunc = (funcName, params, callback) => {
    if (!params) {
        params = {}
        callback = noop
    } else if (typeof params === 'function') {
        callback = params
        params = {}
    }
    if (typeof callback !== 'function') {
        callback = noop
    }
    connectWebviewJavascriptBridge($bridge => {
        $bridge.callHandler(funcName, params, callback)
    })
}

export const registerBridgeFunc = (funcName, handler) => {
    connectWebviewJavascriptBridge($bridge => {
        $bridge.registerHandler(funcName, handler)
    })
}