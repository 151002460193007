import './header.css'
import { useEffect, useState } from 'react'
import { getActivityInfo } from '../service'
import { getDateTime } from '../../../common/util'
import { getActivityRecord } from './service'
import PubSub from 'pubsub-js'

function renderBarrage(item, i) {
    return <div key={item.nickname + '_' + i} className={`barrage-item color-${item.color}`}>
        <span className='barrage-nickname'>用户：{item.nickname}</span>抽中{item.remark}
    </div>
}

const randomStyle1 = { marginLeft: `-${Math.floor(Math.random() * 40)}vw`, animationDuration: `${Math.floor(Math.random() * 15) + 45}s` }
const randomStyle2 = { marginLeft: `-${Math.floor(Math.random() * 40)}vw`, animationDuration: `${Math.floor(Math.random() * 15) + 45}s` }
const randomStyle3 = { marginLeft: `-${Math.floor(Math.random() * 40)}vw`, animationDuration: `${Math.floor(Math.random() * 15) + 45}s` }

function NewYearHeader({ showRuleDialog }) {
    const [timeInfo, setTimeInfo] = useState("")
    const [line1, setLine1] = useState([])
    const [line2, setLine2] = useState([])
    const [line3, setLine3] = useState([])
    const [refreshIndex, setRefreshIndex] = useState(1)

    useEffect(() => {
        getActivityInfo(1).then(data => {
            setTimeInfo(`活动时间：${getDateTime(data.start_time)}-${getDateTime(data.end_time)}`)
        })

        const listener = PubSub.subscribe('DrawEnd', () => {
            setRefreshIndex(ri => ri + 1)
        })
        return () => {
            PubSub.unsubscribe(listener)
        }
    }, [])

    useEffect(() => {
        getActivityRecord(1).then(data => {
            data.forEach(d => {
                let r = Math.random() * 3
                d.color = Math.floor(r) + 1
            })
            const line1Data = data.slice(0, 10)
            const line2Data = data.slice(10, 20)
            const line3Data = data.slice(20)
            setLine1([].concat(line1Data, line1Data))
            setLine2([].concat(line2Data, line2Data))
            setLine3([].concat(line3Data, line3Data))
        })
    }, [refreshIndex])

    return <div className='newyear-header'>
        <div className='newyear-header-time'>{timeInfo}</div>
        <div className='newyear-rule-btn' onClick={showRuleDialog}></div>
        <div className='barrage-container'>
            <div className='barrage-box'>
                <div className='barrage-line' style={randomStyle1}>
                    {
                        line1.map(renderBarrage)
                    }
                </div>
                <div className='barrage-line' style={randomStyle2}>
                    {
                        line2.map(renderBarrage)
                    }
                </div>
                <div className='barrage-line' style={randomStyle3}>
                    {
                        line3.map(renderBarrage)
                    }
                </div>
            </div>
        </div>
    </div>
}

export default NewYearHeader