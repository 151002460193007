import './index.css'
import { useState } from 'react'
import NewYearHeader from './header'
import NewYearBody from './body'
import NewYearFooter from './footer'

function NewYearActivity() {
    const [showRule, toggleRule] = useState(false)
    const [prize, setPrize] = useState(null)

    const showRuleDialog = () => {
        toggleRule(true)
    }

    const closeRuleDialog = () => {
        toggleRule(false)
    }

    const closePrize = () => {
        setPrize(null)
    }

    return <div className='app-page-container'>
        <div className='app-page-window'>
            <div>
                <NewYearHeader showRuleDialog={showRuleDialog} />
                <NewYearBody setPrize={setPrize} />
                <NewYearFooter />
            </div>
        </div>
        {
            showRule && <div className='app-page-container dialog nest'>
                <div className='newyear-rule-container'>
                    <i className='newyear-rule-close' onClick={closeRuleDialog}></i>
                </div>
            </div>
        }
        {
            prize && <div className='app-page-container dialog nest'>
                <div className='newyear-prize-container'>
                    <i className='newyear-prize-close' onClick={closePrize}></i>
                    <div className='newyear-prize-box'>
                        <div className='newyear-prize-dialog-line'>
                            <div className={`newyear-prize prize-${prize.type + 1}`}></div>
                        </div>
                    </div>
                    <div className='newyear-prize-name'>{prize.remark}</div>
                    <div className='newyear-prize-btn-line'>
                        <button className='newyear-prize-btn' onClick={closePrize}></button>
                    </div>
                </div>
            </div>
        }
    </div>
}

export default NewYearActivity