import './footer.css'

function NewYearFooter() {
    return <div className='newyear-footer'>
        <div className='newyear-prize-line line-1'>
            <div className='newyear-prize prize-1'>
                <div className='newyear-prize-text text-1'></div>
            </div>
            <div className='newyear-prize prize-2'>
                <div className='newyear-prize-text text-2'></div>
            </div>
            <div className='newyear-prize prize-2'>
                <div className='newyear-prize-text text-3'></div>
            </div>
        </div>
        <div className='newyear-prize-line line-2'>
            <div className='newyear-prize prize-2'>
                <div className='newyear-prize-text text-4'></div>
            </div>
            <div className='newyear-prize prize-3'>
                <div className='newyear-prize-text text-5'></div>
            </div>
            <div className='newyear-prize prize-3'>
                <div className='newyear-prize-text text-6'></div>
            </div>
        </div>
        <div className='newyear-prize-line line-3'>
            <div className='newyear-prize prize-4'>
                <div className='newyear-prize-text text-7'></div>
            </div>
            <div className='newyear-prize prize-4'>
                <div className='newyear-prize-text text-8'></div>
            </div>
            <div className='newyear-prize prize-4'>
                <div className='newyear-prize-text text-9'></div>
            </div>
        </div>
    </div>
}

export default NewYearFooter