// import App from './pages/index/App'
// import Login from './pages/login'
// import Sms from './pages/login/sms'
// import Game from './pages/game'
// import Store from './pages/store'
// import Search from './pages/search'
// import Collect from './pages/collect'
// import UserCenter from './pages/uc'
// import GameRank from './pages/gameRank'
import AdminEditer from './pages/adminEditer'
import Activity from './pages/activity/newYear'
import Package from './pages/activity/package'

const Router = [
    // { path: '/', element: <App /> },
    // { path: '/login', element: <Login /> },
    // { path: '/sms/:phone', element: <Sms /> },
    // { path: '/game/:gameId', element: <Game /> },
    // { path: '/store', element: <Store /> },
    // { path: '/search', element: <Search /> },
    // { path: '/collect', element: <Collect /> },
    // { path: '/uc', element: <UserCenter /> },
    { path: '/adminEditer', element: <AdminEditer /> },
    { path: '/activity', element: <Activity /> },
    { path: '/package', element: <Package /> }
    // { path: '/gameRank', element: <GameRank /> }
]

export default Router